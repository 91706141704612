





















































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import { namespace } from "vuex-class";
import { VTextField } from "vuetify/lib";
import { FileModel } from "@/model/file/fileModel";
import { LoadingStatusType } from "@/enums/enums";
const StoreAccountingFrim = namespace("AccountingFrim");
@Component({ components: {} })
export default class AccountingFirmEdit extends Vue {
  private internalCode = "";
  private namethai = "";
  private nameeng = "";
  private afprefcode = "";
  private contactname = "";
  private address = "";
  private phonenumber = "";
  private email = "";
  private color = "";
  private logoUrl: any = {};
  private renderImg = true;
  private id = "";
  private isDeleteImage = false;
  private accountingFirmDetail: any = null;
  private currentLogoUrl = "";
  private updateLogo = false;

  @StoreAccountingFrim.Action
  private DoGetAccountingFirmDetail!: (id: string) => void;
  @StoreAccountingFrim.Getter
  private getAccountingFirmsDetailLoadingStatus!: LoadingStatusType;
  @StoreAccountingFrim.Getter
  private getAccountingFirmsDetail!: any | null;
  @StoreAccountingFrim.Getter
  private getUpdateAccountingFirmsLoadingStatus!: any;
  @StoreAccountingFrim.Action
  private DoUpdateAccountingFirm!: (inputRequest: any) => void;

  validateItemRequired(value: string | null | undefined): boolean | string {
    if (!value) {
      return "Item is required";
    }
    if (value.trim().length > 256) {
      return "Input exceeds the maximum length";
    }
    return true;
  }
  validateAddress(value: string | null | undefined): boolean | string {
    if (!value) {
      return "Address is required";
    }
    if (value.trim().length > 1000) {
      return "Address exceeds the maximum length";
    }
    return true;
  }
  validatePhoneNumber(value: string | null | undefined): boolean | string {
    if (!value) {
      return "Phone Number is required";
    }
    if (value.trim().length > 50) {
      return "Phone Number exceeds the maximum length";
    }
    return true;
  }

  created() {
    this.GetAccountingFirmDetail();
  }
  @Watch("logoUrl")
  logoUrlChanged(newVal: any) {
    if (newVal) {
      this.updateLogo = true;
    }
  }
  @Watch("getUpdateAccountingFirmsLoadingStatus", { immediate: true })
  getUpdateAccountingFirmsLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      alert(`Edit ${this.internalCode} Successfully.`);
      this.AccountingFirmDetail(this.getAccountingFirmsDetail.id);
    }
  }

  @Watch("getAccountingFirmsDetailLoadingStatus", { immediate: true })
  getAccountingFirmsDetailLoadingStatusChanged(newVal: LoadingStatusType) {
    if (newVal === LoadingStatusType.Success) {
      if (this.getAccountingFirmsDetail) {
        this.currentLogoUrl = this.getAccountingFirmsDetail.logoFullUrl;
        this.internalCode = this.getAccountingFirmsDetail.internalCode;
        this.namethai = this.getAccountingFirmsDetail.name;
        this.nameeng = this.getAccountingFirmsDetail.nameEn;
        this.afprefcode = this.getAccountingFirmsDetail.afpRefCode;
        this.address = this.getAccountingFirmsDetail.address;
        this.phonenumber = this.getAccountingFirmsDetail.phoneNumber;
        this.email = this.getAccountingFirmsDetail.email;
        this.contactname = this.getAccountingFirmsDetail.contactName;
      }
    }
  }
  public GetAccountingFirmDetail() {
    const emtStr: any = this.$route.query.id;
    this.id = emtStr;
    this.DoGetAccountingFirmDetail(this.id);
  }
  public GetFileModel(file: any) {
    const fileType = file.getChosenFile();
    return file.getChosenFile();
  }
  public Save() {
    let fields = [
      this.$refs.namethaiField,
      this.$refs.nameengField,
      this.$refs.contactnameField,
      this.$refs.addressField,
      this.$refs.phonenumberField,
      this.$refs.emailField,
    ];

    let isValid = true;
    fields.forEach((element: any) => {
      if (element && !element.validate()) {
        isValid = false;
        element.validate(true);
      }
    });
    if (isValid) {
      let logoUrl: FileModel | null = this.logoUrl;
      if (this.updateLogo && this.logoUrl.hasImage()) {
        logoUrl = this.GetFileModel(this.logoUrl);
      } else {
        logoUrl = null;
      }
      const inputRequest: any = {
        id: this.getAccountingFirmsDetail.id,
        name: this.namethai.trim(),
        nameEn: this.nameeng.trim(),
        afpRefCode: this.afprefcode,
        internalCode: this.internalCode,
        contactName: this.contactname.trim(),
        address: this.address.trim(),
        phoneNumber: this.phonenumber.trim(),
        email: this.email.trim(),
        logoUrl: logoUrl,
        currentLogoUrl: this.getAccountingFirmsDetail.logoUrl,
        deleteLogo: this.isDeleteImage,
      };
      this.DoUpdateAccountingFirm(inputRequest);
    } else {
      alert("กรุณาตรวจสอบข้อมูลใหม่อีกครั้ง");
    }
  }
  public AccountingFirmDetail(id: any) {
    const routeData = this.$router.resolve({
      name: "accountingFirmDetail",
      query: { id: id },
    });
    window.location.href = routeData.href;
  }
  public Cancel() {
    this.$router.push({
      name: "accountingFirmTable",
    });
  }
  public DeleteImage() {
    this.currentLogoUrl = "";
    this.isDeleteImage = true;
  }
  public CancelDeleteImage() {
    this.currentLogoUrl = this.getAccountingFirmsDetail.logoFullUrl;
    this.isDeleteImage = false;
  }
}
